import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import "bootstrap/dist/css/bootstrap.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MyThemeProvider } from "./ThemeContext";
import AppProvider from "./provider/AppContext";
import { store } from "./redux/store/index";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById("root"));

const WrappedApp = () => (
  <React.Fragment>
    <Provider store={store}>
      <AppProvider>
        <MyThemeProvider>
          <GoogleOAuthProvider clientId="495135623240-aam2l301qptagr6cue53fsbv1abik74k.apps.googleusercontent.com">
            <App />
          </GoogleOAuthProvider>
        </MyThemeProvider>
      </AppProvider>
    </Provider>
  </React.Fragment>
);

root.render(<WrappedApp />);
reportWebVitals();
