import React, { useCallback, useContext, useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  GetEventData,
  GetClientData,
  GetClientType,
  GetEmployees,
  GetSCH_REG,
  // GetAllCsvData,
} from "../../redux/reducers/EventReducer";
// Theme
import { useTheme } from "../../ThemeContext";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
// import FullPageLoader from "./FullPageLoader";
import MaterialTable from "material-table";
import { Chip, Paper } from "@mui/material";
import { MultiCascader, SelectPicker } from "rsuite";
import moment from "moment";
import { debounce } from "lodash";
import Checkbox from "@mui/material/Checkbox";
import {
  getContractsById,
  deleteContract,
  updateContractOwnership,
} from "../../services/ContractServices";
import PhantomForm from "../NewContractForm/PhantomContract";
import { ERROR, SUCCESS } from "../../constant";
import { generateToast } from "../../helper";
import { statusDetail } from "../../services/ContractServices";
import { TDInput } from "../../styled";
import {
  getEventsFromCalendar,
  syncCalendar,
} from "../../helper/calendarHelper";
import { AppContext } from "../../provider/AppContext";
import Statistics from "./Statistics";
import NoDataFound from "./NoDataFound";
import CalendarDetailsModal from "./CalendarDetailsModal";
import {
  retrieveFromCookieStorage,
} from "../../helper/cookie";
import {
  GetFilterDataByKey,
  handleCreateEvent,
  handleEventDateValue,
  handleFilter,
  handleFilterArray,
  handleSignDateValue,
} from "../../redux/reducers/ContractsReducer";
import CustomHeader from "../Table/CustomHeader";
import {
  COL_LABELS,
  LIMIT_LABLE,
  QUCIK_FILTER_LABELS,
  getStatusColor,
} from "../../constant/Lables";
import { Modal, Select } from "antd";
import { useTableStyles } from "../../theme";
import CsvExport from "./ExportCsv";
import SkeletonLoadingRow from "../Loaders/SkeletonLoadingRow";
import ArticleIcon from "@mui/icons-material/Article";
import { convertNumToFormatString } from "../../helper/CostHelpers";
import { createFilterKey } from "../../services/FilterService";

const { Option } = Select;

export const StatusOption = [
  "Canceled",
  "Sent",
  "Signed/Paid",
  "Signed/Unpaid",
  "In Progress",
  "Reconciled",
  "Postponed",
];
export const InitialFilterColArray = [
  "startDate",
  "createdAt",
  "client_name",
  "client_type",
  "region",
  "sold_by",
  "created_by",
  "amount",
  "cost",
  "profit",
  "contract_status",
  "sign_date",
  "sync",
  "delete",
  "invoiced",
  "profitMargin",
  "hasChildren",
]
const ContractTableMain = ({ handleClick }) => {
  const themeToggle = useTheme();
  const mode = themeToggle.themeState?.mode;
  const [selectedOptions, setSelectedOptions] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);

  const [modal, contextHolder] = Modal.useModal();
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const {
    event: { EventData, isLoading, AllCsvData, trigger },
    Contracts: { filter },
  } = useSelector((state) => state);
  const { user, isAdmin, socket, isStaffer, allEmployees } =
    useContext(AppContext);
  const setFilter = (state) => dispatch(handleFilter(state));
  // const setColFilterArray = (state) => dispatch(handleFilterArray(state));

  const { filterColArray } = filter;

  const handleEvent = () => {
    dispatch(
      GetEventData({
        filter: { ...filter, hasTitle: false },
      })
    );
  };


  const StatusOption = [
    "Canceled",
    "Sent",
    "Signed/Paid",
    "Signed/Unpaid",
    "In Progress",
    "Reconciled",
    "Postponed",
  ];


  const debounceCreateFilter = debounce((key, value) => {
    createFilterKey({ key, value })
      .then(res => {
        console.log("res>>", res);
      })
      .catch(err => {
        console.log("err>>", err);
      });
  }, 1000);

  useEffect(() => {
    debounceCreateFilter("childTablesFilter", filter);
  }, [filter]);


  const handleLimitCheck = (value) => {
    setFilter({ ...filter, limit: value, currentPage: 1 });
  };

  const handleLimitClean = () => {
    setFilter({ ...filter, limit: 5 });
  };

  const handleQuickFilterCheck = (value) => {
    setSelectedOptions(value);
    const filterKeys = {
      phantomContracts: value === "phantomContracts",
      canceledContracts: value === "canceledContracts",
      needBarCalc: value === "needBarCalc",
      sentSigned: value === "sentSigned",
      inProgressSigned: value === "inProgressSigned",
      othersWithNoSignDate: value === "othersWithNoSignDate",
      potentialDuplicates: value === "potentialDuplicates",
      exactDuplicates: value === "exactDuplicates",
      signedBeforeCreation: value === "signedBeforeCreation",
      signedAfterEvent: value === "signedAfterEvent",
      currentPage: 1,
    };
    setFilter({ ...filter, ...filterKeys, quickFilterValue: value });
  };


  const handleCheck = (e) => {
    setFilter({ ...filter, filterColArray: e });
  };

  const total = Number(EventData?.total);
  const totalPages = Math.ceil(total / filter?.limit);

  const debounceGetEventData = debounce(() => {
    handleEvent();
  }, 1000);


  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(GetFilterDataByKey('childTablesFilter'));
      } finally {
        setInitialLoad(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (!initialLoad) {
      handleEvent();
      dispatch(GetClientData());
      dispatch(GetClientType());
      dispatch(GetEmployees());
      dispatch(GetSCH_REG());
      // dispatch(GetAllCsvData());
      const startDate = retrieveFromCookieStorage("startCreatedDate");
      const endDate = retrieveFromCookieStorage("endCreatedDate");
      if (startDate && endDate && ![startDate, endDate].includes("undefined")) {
        setCreateDateValue([moment(startDate), moment(endDate)]);
      }
      const startSignDate = retrieveFromCookieStorage("startSignDate");
      const endSignDate = retrieveFromCookieStorage("endSignDate");
      if (
        startSignDate &&
        endSignDate &&
        ![startSignDate, endSignDate].includes("undefined")
      ) {
        setSignDateValue([moment(startSignDate), moment(endSignDate)]);
      }
      const savedOption = filter.quickFilterValue;
      if (savedOption !== null && savedOption !== "undefined") {
        setSelectedOptions(savedOption);
      }
      // eslint-disable-next-line
    }
  }, [initialLoad]);

  useEffect(() => {
    if (!initialLoad) {
      debounceGetEventData();
    }
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    if (!initialLoad) {
      handleEvent();
    }
    // eslint-disable-next-line
  }, [trigger]);

  const handlePageChange = (event, page) => {
    setFilter({ ...filter, currentPage: page });
  };
  const setCreateDateValue = (value) => {
    dispatch(handleCreateEvent(value));
  };
  const setEventDateValue = (value) => {
    dispatch(handleEventDateValue(value));
  };
  const setSignDateValue = (value) => {
    dispatch(handleSignDateValue(value));
  };

  const TOTALREV = convertNumToFormatString(EventData?.revenueSum);
  const TOTALCOST = convertNumToFormatString(EventData?.totalCost);
  const TOTALPROFIT = convertNumToFormatString(EventData?.profitAmount);

  const PROFITMARGIN = EventData?.profitPercentage?.toFixed(2);
  const [showForm, setShowForm] = useState();
  const handleRowClick = (event, rowData, column) => {
    if (isStaffer) return;
    getContractsById(rowData.id)
      .then((res) => {
        rowData.isPhantom ? setShowForm(res.data) : handleClick(res.data);
      })
      .catch((err) => {
        console.log({ err });
        console.log(err.message);
        generateToast(
          err.message ||
          "Error in saving updates, Please refresh site & try again!",
          ERROR
        );
      });
  };

  const handleRowDelete = (rowData) => {
    modal.confirm({
      title: "Delete Event",
      content: "Would you like to remove this event?",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await deleteContract(rowData.id);
          handleEvent();
          generateToast("Row deleted successfully", SUCCESS);
        } catch (err) {
          generateToast("Error while deleting the row", ERROR);
        }
      },
    });
  };

  const [activeButton, setActiveButton] = useState("All");
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };
  
  const debounceSetFilter = debounce((value) => {
    setFilter({ ...filter, search: value, currentPage: 1 });
  }, 500);

  const handleStatusUpdate = async (event, rowData) => {
    await statusDetail(rowData.id, { contractStatus: event.target.value })
      .then(() => {
        handleEvent();
      })
      .catch((err) => {
        console.log(err, "ERROR WHILE UPDATING THE STATUS");
      });
  };

  const handleInvoicedUpdate = async (event, rowData) => {
    await statusDetail(rowData.id, { isInvoiced: !rowData.isInvoiced })
      .then(() => {
        handleEvent();
      })
      .catch((err) => {
        console.log(err, "ERROR WHILE UPDATING THE STATUS");
      });
  };
  const handleSignDateChange = (e, rowData) => {
    if (
      moment(e.target.value) < moment("2000-01-02") ||
      moment(e.target.value) > moment(rowData?.startDate)
    ) {
      generateToast("Please add valid date", ERROR);
    } else {
      statusDetail(rowData.id, { signDate: e.target.value || null })
        .then(() => {
          handleEvent();
        })
        .catch((err) => {
          console.log(err, "ERROR WHILE UPDATING THE SIGNDATE");
        });
    }
  };
  const [showCalendarForm, setShowCalendarForm] = useState(false);
  const [currentCalendarData, setCurrentCalendarData] = useState("");
  const [updatedCalendarData, setUpdatedCalendarData] = useState("");
  const [soldByLoading, setSoldByLoading] = useState(null);
  const [resData, setResData] = useState();
  const handleClose = () => {
    setShowCalendarForm(false);
  };
  const handleCallback = async (param) => {
    await statusDetail(resData.id, {
      ...param,
      socket: socket.id,
    })
      .then(() => {
        handleEvent();
        handleClose();
      })
      .catch((err) => {
        console.log(err, "ERROR WHILE SYNCING");
        generateToast(err.message, ERROR);
      });
  };
  const handleUpdate = () => {
    syncCalendar(resData, handleCallback, user, updatedCalendarData);
  };

  const handleSoldByChange = (userId, eventId) => {
    setSoldByLoading(eventId)
    updateContractOwnership(eventId, { soldBy: userId })
      .then((res) => {
        generateToast(res.message, SUCCESS);
        handleEvent();
      })
      .catch(err => {
        generateToast(err.data.message, ERROR);
      })
      .finally(() => {
        setSoldByLoading(null);
      })
  }

  const columns = [
    {
      title: "Created Date",
      sorting: true,
      hidden: !filterColArray?.includes("createdAt"),
      render: (rowData) => {
        if (rowData.isPhantom) {
          return (
            <>
              <i className="fa-solid fa-ghost fa-ghost-custom" />
              {rowData.createdDate}
            </>
          );
        } else {
          return rowData.createdDate;
        }
      },
    },
    {
      title: "Event Date",
      sorting: true,
      hidden: !filterColArray?.includes("startDate"),
      render: (rowData) => {
        if (rowData.isPhantom) {
          return (
            <>
              <i className="fa-solid fa-ghost fa-ghost-custom" />
              {rowData.eventDate}
            </>
          );
        } else {
          return rowData.eventDate;
        }
      },
    },
    {
      title: "Client",
      sorting: true,
      render: (rowData) => rowData.eventClients[0]?.client?.name || "",
      hidden: !filterColArray?.includes("client_name"),
    },
    {
      title: "Client Type",
      sorting: true,
      render: (rowData) => rowData.clientType || "",
      hidden: !filterColArray?.includes("client_type"),
    },
    {
      title: "SCH/REG",
      sorting: true,
      render: (rowData) => rowData.collaborator || "TBD",
      hidden: !filterColArray?.includes("region"),
    },
    {
      title: "Sold by",
      sorting: true,
      render: (rowData) => {
        return (
          <div id="contract-main-table" className="tw-flex tw-items-center">
            {rowData.isPhantom && (
              <i className="fa-solid fa-ghost fa-ghost-custom" />
            )}
            <Select
              showSearch
              style={{
                width: "100%",
                backgroundColor: "transparent",
                fontSize: "12px",
              }}
              placeholder="Select a person"
              optionFilterProp="children"
              showArrow={isAdmin}
              disabled={!isAdmin || soldByLoading === rowData?.id}
              onChange={(userId) => handleSoldByChange(userId, rowData?.id)}
              value={rowData?.soldBy}
              loading={soldByLoading === rowData?.id}
              onClick={(e) => isAdmin && e.stopPropagation()}
            >
              {allEmployees.map((item, index) => {
                return (
                  <Option key={index} value={item.id}>
                    {item.firstName + " " + item.lastName + soldByLoading}
                  </Option>
                );
              })}
            </Select>
          </div>
        );
      },
      hidden: !filterColArray?.includes("sold_by"),
    },
    {
      title: "Created By",
      sorting: true,
      render: (rowData) => rowData?.createdBy,
      hidden: !filterColArray?.includes("created_by") || isStaffer,
    },
    {
      title: "Amount",
      sorting: true,
      render: (rowData) => {
        const cost = parseFloat(rowData?.clientBudget);
        if (isNaN(cost)) {
          return "$0";
        } else {
          return "$" + convertNumToFormatString(cost);
        }
      },
      hidden: !filterColArray?.includes("amount") || isStaffer,
    },
    {
      title: "Cost",
      sorting: true,
      render: (rowData) => {
        const cost = parseFloat(rowData?.totalCost);
        if (isNaN(cost)) {
          return "$0";
        } else {
          return "$" + convertNumToFormatString(cost);
        }
      },
      hidden: !filterColArray?.includes("cost") || isStaffer,
    },
    {
      title: "Profit(s)",
      sorting: true,
      render: (rowData) => {
        const profit = parseFloat(rowData?.profit);
        if (isNaN(profit)) {
          return "$0";
        } else {
          return "$" + convertNumToFormatString(profit);
        }
      },
      hidden: !filterColArray?.includes("profit") || isStaffer,
    },
    {
      title: "ProfitMargin",
      sorting: true,
      render: (rowData) => (rowData?.profitMargin * 100).toFixed(2) + "%",
      hidden: !filterColArray?.includes("profitMargin") || isStaffer,
    },
    {
      title: "Status",
      sorting: true,
      render: (rowData, index) => (
        <select
          index={index}
          className="input-status"
          style={{
            backgroundColor:
              rowData?.status === "In Progress"
                ? "lightgray"
                : getStatusColor(rowData),
          }}
          onClick={(e) => {
            e.persist();
            e?.stopPropagation();
          }}
          value={rowData.status}
          onChange={(e) => {
            e.persist();
            e?.preventDefault();
            e?.stopPropagation();
            handleStatusUpdate(e, rowData);
          }}
        >
          {StatusOption.map((option, optionIndex) => (
            <option
              disabled={
                !isAdmin && ["Signed/Paid", "Reconciled"].includes(option)
              }
              key={optionIndex}
              value={option}
            >
              {option}
            </option>
          ))}
        </select>
      ),
      hidden: !filterColArray?.includes("contract_status") || isStaffer,
    },

    {
      title: "Sign Date",
      sorting: true,
      hidden: !filterColArray?.includes("sign_date") || isStaffer,
      render: (rowData) => (
        <TDInput
          disabled={
            rowData?.status === "Signed/Unpaid" ||
              rowData?.status === "Signed/Paid"
              ? false
              : true
          }
          color={mode === "dark" ? "#000 " : "#1E1E1E "}
          name="signDate"
          className="tw-w-[85%]"
          onChange={(e) => {
            e.persist();
            e?.preventDefault();
            e?.stopPropagation();
            handleSignDateChange(e, rowData);
          }}
          onClick={(e) => {
            e.persist();
            e?.stopPropagation();
          }}
          min={moment("2000-01-02").format("YYYY-MM-DD")}
          max={
            rowData?.status === "Signed/Unpaid" ||
              rowData?.status === "Signed/Paid"
              ? moment(rowData?.startDate).format("YYYY-MM-DD")
              : ""
          }
          value={rowData?.signDate || ""}
          type="date"
          style={{
            padding: "6px",
            borderRadius: "3px",
            backgroundColor:
              rowData?.status === "In Progress"
                ? "lightgray"
                : getStatusColor(rowData),
          }}
        ></TDInput>
      ),
    },

    {
      title: "Invoiced",
      sorting: true,
      hidden: !filterColArray?.includes("invoiced") || isStaffer,
      render: (rowData) => (
        <Checkbox
          onClick={(e) => {
            e.persist();
            e?.stopPropagation();
          }}
          checked={rowData.isInvoiced}
          onChange={(e) => {
            e.persist();
            e?.preventDefault();
            e?.stopPropagation();
            handleInvoicedUpdate(e, rowData);
          }}
        />
      ),
    },
    {
      title: "Google",
      sorting: true,
      hidden: !filterColArray?.includes("sync") || isStaffer,
      render: (rowData) => {
        const handleCallback = async (param) => {
          await statusDetail(rowData.id, {
            ...param,
            socket: socket.id,
          })
            .then(() => {
              handleEvent();
            })
            .catch((err) => {
              console.log(err, "ERROR WHILE SYNCING");
            });
        };

        const handleSync = (e) => {
          e.persist();
          e?.preventDefault();
          e?.stopPropagation();
          getContractsById(rowData.id).then((res) => {
            const data = res.data;
            setResData(data);
            if (!data?.calendar) {
              syncCalendar(res.data, handleCallback, user);
            } else {
              getEventsFromCalendar(
                data,
                (events, updatedData) => {
                  setShowCalendarForm(true);
                  setCurrentCalendarData(events?.description);
                  setUpdatedCalendarData(updatedData?.description);
                },
                () => {
                  syncCalendar(res.data, handleCallback, user);
                }
              );
            }
          });
        };

        return (
          <>
            <div
              onClick={handleSync}
              className="pointer"
              style={{
                fontSize: "12px",
              }}
            >
              <i
                className="login-icon fas fa-calendar"
                style={{
                  marginRight: "5px",
                }}
              ></i>
              Sync
            </div>
          </>
        );
      },
    },

    {
      title: "Actions",
      sorting: false,
      render: (rowData) => (
        <>
          <DeleteForeverIcon
            className="delete-icon"
            onClick={(e) => {
              e.persist();
              e?.preventDefault();
              e?.stopPropagation();
              handleRowDelete(rowData);
            }}
          />
        </>
      ),
      hidden: !filterColArray?.includes("delete") || isStaffer,
    },
  ];

  return (
    <>
      {contextHolder}
      <CalendarDetailsModal
        show={showCalendarForm}
        handleClose={handleClose}
        setUpdatedCalendarData={setUpdatedCalendarData}
        currentData={currentCalendarData}
        updatedData={updatedCalendarData}
        handleUpdate={handleUpdate}
      />
      <div
        style={{
          backgroundColor: mode === "dark" ? "#1E1E1E" : "white",
        }}
        className="main"
      >
        <PhantomForm
          {...{
            setShowForm,
            showForm,
            contractState: showForm,
          }}
        />
        <div className="ms-2 me-2">
          {!isStaffer && (
            <Statistics
              TOTALREV={TOTALREV}
              PROFITMARGIN={PROFITMARGIN}
              TOTALPROFIT={TOTALPROFIT}
              TOTALCOST={TOTALCOST}
              mode={mode}
            />
          )}
          <div className="toggle-filter-chip-container">
            <section className="d-flex">
              <div className="d-flex flex-wrap gap-1">
                <button
                  className={`thead-buttons ${["All", "Reset All Filters"].includes(activeButton)
                    ? "thead-buttons-active"
                    : ""
                    } `}
                  style={{
                    backgroundColor: mode === "dark" ? "#2B2B2B" : "#e8eaec",
                    color:
                      mode === "dark" || activeButton === "All"
                        ? "#fff"
                        : "#3A4450",
                    border: mode === "dark" ? "1px solid #4C4C4C" : "none",
                  }}
                  onClick={() => {
                    handleButtonClick("All");
                    setFilter({
                      ...filter,
                      needBarCalc: "",
                      canceledContracts: "",
                    });
                  }}
                >
                  All
                </button>
                <button
                  className={`thead-buttons ${activeButton === "Cancelled" ? "thead-buttons-active" : ""
                    } `}
                  style={{
                    backgroundColor: mode === "dark" ? "#2B2B2B" : "#e8eaec",
                    color:
                      mode === "dark" || activeButton === "Cancelled"
                        ? "#fff"
                        : "#3A4450",
                    border: mode === "dark" ? "1px solid #4C4C4C" : "none",
                  }}
                  onClick={() => {
                    handleButtonClick("Cancelled");
                    setFilter({
                      ...filter,
                      canceledContracts: true,
                      needBarCalc: "",
                    });
                  }}
                >
                  Cancelled
                </button>
                <button
                  className={`thead-buttons ${activeButton === "Need for calc"
                    ? "thead-buttons-active"
                    : ""
                    } `}
                  style={{
                    backgroundColor: mode === "dark" ? "#2B2B2B" : "#e8eaec",
                    color:
                      mode === "dark" || activeButton === "Need for calc"
                        ? "#fff"
                        : "#3A4450",
                    border: mode === "dark" ? "1px solid #4C4C4C" : "none",
                  }}
                  onClick={() => {
                    handleButtonClick("Need for calc");
                    setFilter({
                      ...filter,
                      needBarCalc: true,
                      canceledContracts: "",
                    });
                  }}
                >
                  Need for calc
                </button>
                <button
                  className={`thead-buttons ${activeButton === "Reset All Filters"
                    ? "thead-buttons-active"
                    : ""
                    } `}
                  style={{
                    backgroundColor: mode === "dark" ? "#2B2B2B" : "#e8eaec",
                    color:
                      mode === "dark" || activeButton === "Reset All Filters"
                        ? "#fff"
                        : "#3A4450",
                    border: mode === "dark" ? "1px solid #4C4C4C" : "none",
                  }}
                  onClick={() => {
                    handleButtonClick("Reset All Filters");
                    setFilter({
                      limit: 5,
                      currentPage: 1,
                      filterColArray: InitialFilterColArray
                    });
                    setCreateDateValue(null);
                    setSignDateValue(null);
                    setEventDateValue(null);
                  }}
                >
                  Reset All Filters
                </button>

                <div className="form-group d-flex align-items-center justify-content-center search-input-container">
                  <button className="p-3" disabled>
                    <i className="fas fa-search"></i>
                  </button>
                  <input
                    type="text"
                    defaultValue={filter?.search}
                    className="search-input"
                    placeholder="Search Here"
                    onChange={(e) => debounceSetFilter(e.target.value)}
                  />
                </div>
              </div>
            </section>
            <div
              style={{
                marginLeft: "auto",
                padding: 4,
              }}
            >
              {EventData ? (
                <Chip
                  label={
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "bolder",
                        color: mode === "dark" ? "white" : "black",
                      }}
                    >
                      <ArticleIcon
                        sx={{
                          fontSize: "18px",
                          marginBottom: "3px",
                          marginRight: "4px",
                        }}
                      />
                      Total Contracts: {EventData?.total}
                    </span>
                  }
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <Paper elevation={2} className={classes.tableContainer}>
          <MaterialTable
            columns={columns}
            isLoading={isLoading}
            data={EventData?.data?.map((event) => ({
              ...event,
              createdDate: moment(event.createdAt).format("MM/DD/YYYY") || "",
              eventDate: moment(event.startDate).format("MM/DD/YYYY") || "",
              client: event.eventClients[0]?.client?.name || "",
              clientType: event.clientType?.name || "",
              collaborator:
                event.eventClients[0]?.collaborator?.name ||
                event.region?.name ||
                "",
              soldBy: `${event.soldBy?.firstName || ""} ${event.soldBy?.lastName || ""
                }`,
              createdBy: `${event.createdBy?.firstName || ""} ${event.createdBy?.lastName || ""
                }`,
              profit: (event.clientBudget - event.totalCost).toFixed(2),
              signDate: event.signDate || "",
              google: event.google || "",
              status: event.contractStatus,
              startDate: event.startDate,
            }))}
            onRowClick={handleRowClick}
            options={{
              striped: false,
              sorting: true,
              paging: false,
              headerStyle: { position: "sticky", top: 0 },
              maxBodyHeight: "550px",
              addRowPosition: "first",
              actionsColumnIndex: -1,
              showFooter: false,
              selectionProps: (rowData) => ({}),
              columnsButton: true,
              cellStyle: (cellData, rowData) => {
                let backgroundColor = "";
                backgroundColor = getStatusColor(rowData);
                return {
                  backgroundColor: backgroundColor,
                  textAlign: "center",
                  width: "10rem",
                };
              },
            }}
            components={{
              Header: CustomHeader,
              Toolbar: () => null,
              ...(isLoading && {
                Row: () => (
                  <SkeletonLoadingRow columnCount={filterColArray?.length} />
                ),
              }),
            }}
            localization={{
              body: {
                emptyDataSourceMessage: <NoDataFound mode={mode} />,
              },
            }}
          />
        </Paper>

        <section className="d-flex overflow-hidden">
          <div className="d-flex align-items-center flex-wrap gap-1">
            <div>
              <Stack spacing={2}>
                <Pagination
                  count={totalPages || 7}
                  variant="outlined"
                  shape="rounded"
                  classes={{ root: classes.root }}
                  page={filter?.currentPage}
                  onChange={handlePageChange}
                />
              </Stack>
            </div>

            <div>
              <SelectPicker
                placement="topStart"
                value={filter?.limit}
                searchable={false}
                onChange={handleLimitCheck}
                onClean={handleLimitClean}
                data={LIMIT_LABLE}
                placeholder="Show 5"
                className="limit"
              />
            </div>
            <div>
              <SelectPicker
                data={QUCIK_FILTER_LABELS}
                value={selectedOptions}
                placement="topStart"
                placeholder="Quick Filters"
                limit={1}
                cascade={false || undefined}
                searchable={false}
                // menuWidth={230}
                // menuHeight={230}
                onChange={handleQuickFilterCheck}
                className="quick-filter"
              />
            </div>
            <div>
              <MultiCascader
                data={COL_LABELS}
                defaultValue={filterColArray}
                placement="topStart"
                placeholder="Selected Columns"
                searchable={false}
                // menuWidth={230}
                // menuHeight={230}
                onClean={() => handleCheck([])}
                onCheck={handleCheck}
                className="quick-filter"
              />
            </div>
            <CsvExport
              mode={mode}
              EventData={EventData}
              AllCsvData={AllCsvData}
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default ContractTableMain;
