import React, { useContext, useState } from "react";
import { AppContext } from "../../provider/AppContext";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from '@react-oauth/google';
import { ERROR, SUCCESS } from "../../constant";
import { loginUser } from "../../services/AuthServices";
import { employeeDetail } from "../../services/EmployeeService";
import Message from "../../components/Message/Message";
import { useTheme } from "../../ThemeContext";
import { Loader } from "rsuite";
import { generateToast } from "../../helper";
import { post } from "../../services/ServicesApi";
import './styles/index.css'
const Login = ({ setAuth }) => {
  const { setUser, handleClearStorage, socket } = useContext(AppContext);
  const navigate = useNavigate();
  const themeToggle = useTheme();
  const { mode } = themeToggle.themeState;

  const [showPassword, setShowPassword] = useState(false);
  // eslint-disable-next-line
  const [toasterMsg, setToasterMsg] = useState(null);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    remember: true,
  });

  const { email, password, remember } = inputs;

  const login = useGoogleLogin({
    onSuccess: async ({ code }) => {
      const res = await post(`${process.env.REACT_APP_API_HOST}/v1/auth/google`, {
        code,
      });
      handleClearStorage();
      localStorage.setItem("token", res.data.accessToken);
      const employeePromise = employeeDetail(res.data.accessToken);
      employeePromise
        .then((response) => {
          if (response?.message) return navigate("/login");
          socket.emit("login_user", {
            ...(response.employee || {}),
            socketId: socket.id,
          });
          return setUser(response.employee);
        })
        .catch(({ data }) => {
          console.error(data.message);
          if (data.message) {
            navigate("/login");
          }
        });
      setAuth(true);
      generateToast("Login Successful", SUCCESS);
      setLoading(false);
    },
    flow: 'auth-code',
  });

  const onChange = (e) =>
    setInputs({ ...inputs, [e.target.name]: e.target.value });

  const onChecked = (e) =>
    setInputs({ ...inputs, [e.target.name]: e.target.checked });

  const [isLoading, setLoading] = useState(false);
  const onLoginForm = (e) => {
    e.preventDefault();
    setLoading(true);
    const loginPromise = loginUser({ email, password });
    loginPromise
      .then((res) => {
        if (res.data) {
          handleClearStorage();
          remember && localStorage.setItem("token", res.data.accessToken);
          const employeePromise = employeeDetail(res.data.accessToken);
          employeePromise
            .then((response) => {
              if (response?.message) return navigate("/login");
              socket.emit("login_user", {
                ...(response.employee || {}),
                socketId: socket.id,
              });
              return setUser(response.employee);
            })
            .catch(({ data }) => {
              console.error(data.message);
              if (data.message) {
                navigate("/login");
              }
            });
          setAuth(true);
          generateToast("Login Successful", SUCCESS);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log({ err });
        localStorage.removeItem("token");
        setAuth(false);
        generateToast("Something went wrong!", ERROR);
        setLoading(false);
      });
  };

  return (
    <>
      <div
        className={`login-screen-wrapper tw-bg-fixed tw-pt-[136px] tw-w-full tw-h-screen ${
          mode || ""
        }`}
      >
        <div className=" tw-mx-auto">
          <div className="tw-rounded-xl tw-bg-gray-100 tw-dark:bg-[#151515] tw-w-[437px] tw-h-auto tw-py-[35px] tw-mx-auto">
            <div className="tw-flex tw-w-full tw-px-10 tw-flex-col tw-items-center tw-justify-center">
              <img
                src="../images/headerlogo.png"
                className="tw-h-16 tw-text-center"
                alt="Logo"
              />
              <h1 className="tw-text-2xl tw-font-poppins tw-dark:text-white tw-leading-9 tw-text-darkGrey tw-mt-4 tw-font-[600] tw-capitalize">
                Welcome Back!
              </h1>
              <p className="tw-text-base tw-dark:text-white tw-leading-9 tw-text-darkGrey">
                Please sign in to access your account
              </p>
              <form
                onSubmit={onLoginForm}
                className="tw-w-full tw-mt-3 tw-mb-4"
              >
                <div className="tw-text-md tw-w-full">
                  <label className="tw-w-full tw-inline-flex tw-items-center tw-relative">
                    <i className="fa-solid tw-text-lightGrey tw-text-xl fa-envelope tw-absolute tw-inset-y-2/2 tw-top-5 tw-left-3"></i>

                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="tw-leading-5 tw-w-full tw-dark:text-white tw-mt-3 tw-bg-transparent tw-focus:outline-0 tw-border-2 tw-border-gray-300 tw-text-gray-900 tw-rounded-lg tw-focus:ring-blue-500 tw-focus:border-gray-500 tw-block tw-py-2.5 tw-px-10"
                      placeholder="Email"
                      required
                      value={email}
                      onChange={(e) => onChange(e)}
                    />
                  </label>
                  <label className="tw-w-full tw-inline-flex tw-items-center tw-relative">
                    <i className="fa-solid tw-text-lightGrey tw-text-xl fa-lock tw-absolute tw-inset-y-2/2 tw-top-6 tw-left-3"></i>
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      className="tw-leading-5 tw-w-full tw-dark:text-white tw-mt-3 tw-bg-transparent tw-focus:outline-0 tw-border-2 tw-border-gray-300 tw-text-gray-900 tw-rounded-lg tw-focus:ring-blue-500 tw-focus:border-gray-500 tw-block tw-py-2.5 tw-px-10"
                      placeholder="Password"
                      required
                      name="password"
                      value={password}
                      onChange={(e) => onChange(e)}
                    />
                    {showPassword ? (
                      <i
                        onClick={() => setShowPassword(!showPassword)}
                        className="tw-pointer fa-solid tw-text-lightGrey tw-text-md fa-eye tw-absolute tw-inset-y-2/2 tw-top-7 tw-right-3"
                      ></i>
                    ) : (
                      <i
                        onClick={() => setShowPassword(!showPassword)}
                        className="tw-pointer fa-solid tw-text-lightGrey tw-text-md fa-eye-slash tw-absolute tw-inset-y-2/2 tw-top-7 tw-right-3"
                      ></i>
                    )}
                  </label>
                  <div className="tw-flex tw-my-6 tw-text-sm tw-leading-5 tw-justify-between tw-items-center">
                    <label className="tw-inline-flex tw-items-center">
                      <input
                        checked={remember}
                        onChange={onChecked}
                        name="remember"
                        type="checkbox"
                        className="tw-checked:bg-blue-500 tw-w-5 tw-h-5 tw-mr-3"
                      />
                      <p className="tw-text-darkGrey tw-dark:text-white ">
                        Remember me
                      </p>
                    </label>
                    <Link to="/reset">
                      <p className="tw-dark:text-white tw-text-darkGrey ">
                        Forgot Password?
                      </p>
                    </Link>
                  </div>
                </div>

                <button
                  type="submit"
                  className="tw-rounded-md tw-text-white tw-text-xl tw-py-2 tw-mt-3 tw-w-full tw-border-0 tw-bg-blue"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Loader size="sm" content="Loading..." />
                  ) : (
                    "Sign In"
                  )}
                </button>
              </form>
              OR
              <button
                type="button"
                onClick={login}
                class="login-with-google-btn tw-mt-4"
              >
                Sign in with Google
              </button>
              {/* <button onClick={() => login()}>Sign in with Google 🚀</button> */}
            </div>
          </div>
          <p className="tw-text-center tw-mt-4 tw-text-[#FFF]">
            <Link to="/privacy-policy" className="tw-text-[#FFF] tw-font-[500]">
              Privacy Policy
            </Link>
            {" | "}
            <Link
              to="/terms-of-service"
              className="tw-text-[#FFF] tw-font-[500]"
            >
              Terms of Service
            </Link>
          </p>
          <Message messageDetail={toasterMsg} />
        </div>
      </div>
    </>
  );
};

export default Login;
