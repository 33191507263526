import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { retrieveFromCookieStorage } from "../../helper/cookie";
import { predefinedBottomRanges } from "../../helper";
import { getFilterByKey } from "../../services/FilterService";
import { InitialFilterColArray } from "../../components/Contracts/ContractTableMain";

const updateFilterInitialKeys = (filterObj) => {
  if (!filterObj) return;
  if (!filterObj.startEventDate && !filterObj.endEventDate) {
    return {
      startEventDate: predefinedBottomRanges[0].value[0],
      endEventDate: predefinedBottomRanges[0].value[1],
      ...filterObj,
    };
  } else return filterObj;
};

const initialState = {
  filter: {
    startSignDate: "",
    endSignDate: "",
    startCreatedDate: "",
    endCreatedDate: "",
    startEventDate: predefinedBottomRanges[0].value[0],
    endEventDate: predefinedBottomRanges[0].value[1],
    contractStatus: "",
    clientType: "",
    sort: "",
    order: "",
    search: "",
    client: "",
    soldBy: "",
    schoolRegion: "",
    createdBy: "",
    isInvoiced: "",
    phantomContracts: false,
    canceledContracts: false,
    needBarCalc: false,
    sentSigned: false,
    inProgressSigned: false,
    othersWithNoSignDate: false,
    potentialDuplicates: false,
    exactDuplicates: false,
    signedBeforeCreation: false,
    signedAfterEvent: false,
    limit: 5,
    currentPage: 1,
    minTotalCost: "",
    maxTotalCost: "",
    minClientBudget: "",
    maxClientBudget: "",
    minProfit: "",
    maxProfit: "",
    minProfitPercentage: "",
    maxProfitPercentage: "",
    filterColArray: InitialFilterColArray,
  },
  createDateValue: [],
  eventDateValue: [],
  signDateValue: [],
  parentFilter: {
    order: "DESC",
    sort: "",
    search: "",
    currentPage: 1,
    limit: 5,
  },
};

export const GetFilterDataByKey = createAsyncThunk("GetData", async (key) => {
  return getFilterByKey(key);
});


const ContractDataReducer = createSlice({
  name: "contracts",
  initialState,
  reducers: {
    handleFilter: (state, action) => {
      state.filter = action.payload;
      return state;
    },
    handleFilterArray: (state, action) => {
      state.filterColArray = action.payload;
      return state;
    },
    handleParentFilter: (state, action) => {
      state.parentFilter = action.payload;
      return state;
    },
    handleCreateEvent: (state, action) => {
      state.createDateValue = action.payload;
      return state;
    },
    handleEventDateValue: (state, action) => {
      state.eventDateValue = action.payload;
      return state;
    },
    handleSignDateValue: (state, action) => {
      state.signDateValue = action.payload;
      return state;
    },
  },
  extraReducers: {
    [GetFilterDataByKey.fulfilled]: (state, action) => {
      const { meta, payload } = action;
      switch (meta.arg) {
        case 'childTablesFilter':
          state.filter = payload.data;
          break;
        case 'parentTablesFilter':
          state.parentFilter = payload.data;
          break;
        default:
          break;
      }
    },
  },
});
export const {
  handleFilter,
  handleParentFilter,
  handleFilterArray,
  handleCreateEvent,
  handleEventDateValue,
  handleSignDateValue,
} = ContractDataReducer.actions;
export default ContractDataReducer.reducer;
